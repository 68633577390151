.websiteColorPanel {
  --bl-primary: #3170ec;
  --bl-secondary: #edece3;
  --body-bg-light: #f6f6f2;
  --bl-white-text: #fff;
  --bl-white-background: #fff;
  --bl-border: #d7d6ce;
  --bl-dark-1: #030f44;
  --bl-grey-1: #80807c;
  --bl-header: #2a2a29;
  --bl-delete: #f25116;
  --bl-text: #141414;
  --bl-blue-light: #dbe7ff;
  --bl-light-grey: #faf9f7;
  --bl-grey-2: #acaba5;
  --bl-blue-light-2: #eaf1fd;
  --bl-text-light: #f6f6f2;
  --bl-icon-light: #03fc62;
  --bl-icon-red: #a83232;
  --bl-yellow: #fff598;
}

* {
  -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

html {
  -webkit-text-size-adjust: 100%;
}

ul {
  list-style-type: none;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child {
  margin-bottom: 0;
}

img,
video {
  border: none;
  max-width: 100%;
}

a,
input,
img,
select,
textarea {
  outline: 0 none;
}

.primaryBtn,
.secondaryBtn,
.reorderElement,
.deleteIconForElements,
.noStyleBtn,
.selectBtn {
  transition: 0.2s ease-in all;
}

body {
  font-size: 0.875rem;
  background-color: var(--body-bg-light);
}

button {
  cursor: pointer;
}

button,
button span {
  font-size: 14px;
}

.mt-20 {
  margin-top: 20px;
}

.d-flex {
  display: flex;
}

.primaryBtn {
  display: inline-block;
  background: var(--bl-primary);
  padding: 10px 16px;
  border-radius: 8px;
  color: var(--bl-white-text);
  font-size: 14px;
  border: 1px solid var(--bl-primary);
  line-height: normal;
  font-weight: 600;
}

.primaryBtn.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--bl-primary);
  background-color: transparent;
}

.noBGBtn,
.ant-modal-footer button.noBGBtn {
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
}

.ant-btn-text:not(:disabled):not(.ant-btn-disabled).noBGBtn:hover,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled).noBGBtn:hover {
  background-color: transparent;
  color: #cc2525;
  border-color: transparent;
}

.secondaryBtn,
.selectBtn.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  display: inline-block;
  background-color: transparent;
  border: 1px solid var(--bl-primary);
  color: var(--bl-primary);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  padding: 8px 25px;
  border-radius: 8px;
  font-size: 14px;
  height: auto;
  font-weight: 600;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled).secondaryBtn:hover,
.selectBtn.ant-select-outlined:not(.ant-select-customize-input):hover .ant-select-selector,
.selectBtn.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  background-color: var(--bl-primary);
  color: var(--bl-white-text);
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.secondaryBtn:hover {
  background-color: transparent;
}

.audienceCommonWrap,
.featureSetWrap:after {
  transition: 0.3s ease all;
}

.logoContainer {
  display: flex;
  justify-content: space-between;
}

.leftPanelMenu>li:first-child {
  cursor: default;
}

.leftPanelMenu.ant-menu-light>li.ant-menu-item:first-child:hover,
.leftPanelMenu>li.ant-menu-item:first-child.ant-menu-item-selected {
  background-color: transparent;
  box-shadow: none;
}

.left-panel-header {
  justify-content: space-between;
  display: flex;
}

.ant-menu-inline.leftPanelMenu .collapsedLogo {
  display: none;
}

.ant-menu-inline-collapsed.leftPanelMenu .collapsedLogo {
  display: inline-block;
  min-width: 35px;
}

.leftPanelMenu .ant-menu-item .ant-menu-item-icon+span {
  margin-inline-start: 0;
}

.leftPanelWrap {
  position: fixed;
  height: 100%;
  display: flex;
  flex: 0 0 282px;
  z-index: 5;
  transition: 0.3s ease all;
}

.leftPanelMenu.ant-menu {
  background-color: var(--bl-secondary);
}

.collapseBtn,
.collapsedStateCollapseBtn {
  position: absolute;
  top: 30px;
  right: 6px;
  z-index: 9;
  opacity: 0;
}

.ant-menu-inline-collapsed.leftPanelMenu {
  width: 100px;
}

.collapsedStateCollapseBtn {
  right: 156px;
  opacity: 1;
  background-color: transparent;
  border-color: transparent;
  transform: rotate(180deg);
  padding: 0;
  top: 26px;
  box-shadow: none;
  left: 72px;
  width: 24px;
}

.collapsedStateCollapseBtn.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: transparent;
}

.leftPanelMenu.ant-menu-inline .ant-menu-item {
  padding-inline-start: 16px !important;
}

.leftPanelMenu {
  padding: 24px 0;
}

.leftPanelMenu li:last-child.ant-menu-item:not(.ant-menu-item-only-child) {
  position: absolute;
  bottom: 24px;
  height: auto;
  width: auto;
  margin: auto;
  padding-inline: 0 !important;
  font-weight: 500;
}

.leftPanelMenu li:last-child.ant-menu-item-selected {
  background-color: transparent;
}

.leftPanelMenu .ant-menu-item {
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
}

.leftPanelMenu.ant-menu-inline-collapsed>li:not(:first-child):not(:last-child) {
  margin: auto auto 8px auto;
  padding-inline: calc(50% - 22px - 8px);
  height: 56px;
  width: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}

.leftPanelMenu>li:nth-child(2) {
  margin-top: 40px !important;
}

.leftPanelMenu>li:not(:first-child):not(:last-child) {
  margin-inline: 16px;
  width: calc(100% - 32px);
  padding: 25px 0;
}

.leftPanelMenu>li:not(:first-child):not(:last-child) span {
  padding-left: 10px;
  display: inline-block;
}

.leftPanelMenu li:last-child.ant-menu-item li {
  display: flex;
  align-items: center;
}

.leftPanelMenu.ant-menu-inline-collapsed li:last-child.ant-menu-item:not(.leftPanelProfileMenu) li {
  padding-inline: 0;
  width: 100%;
  justify-content: start;
  flex: 0 0 auto;
}

.ant-menu-inline-collapsed li:last-child.ant-menu-item {
  width: 100px;
}

.leftPanelMenu.ant-menu-inline-collapsed li:last-child.ant-menu-item li img {
  margin-right: 0;
}

.leftPanelMenu li:nth-child(2).ant-menu-item img {
  height: 24px;
  width: 24px;
}

.leftPanelMenu li:last-child.ant-menu-item li:not(:last-child) {
  margin-bottom: 8px;
}

.leftPanelMenu.ant-menu-inline-collapsed>li:not(:last-child) {
  max-width: 70px;
  padding-inline: calc(50% - 15px - 12px);
}

.leftPanelMenu.ant-menu-inline-collapsed>li:first-child {
  padding-inline: calc(50% - 17px - 20px);
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-btn:not(:disabled):focus-visible {
  outline: none;
  outline-offset: 0;
  transition: none;
}

.ant-menu-light .ant-menu-item:not(:last-child).ant-menu-item-selected {
  background: var(--bl-white-background);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  color: var(--bl-primary);
}

.ant-menu-light .ant-menu-sub .ant-menu-item:not(:last-child).ant-menu-item-selected {
  box-shadow: none;
  color: var(--bl-primary);
  border-radius: 4px;
}

.audienceCommonWrap {
  margin: 0 0 0 282px;
}

.audienceCommonWrap>* {
  padding: 24px;
}

.leftPanelCollapsed~.audienceCommonWrap {
  margin: 0 0 0 100px;
}

.formWrapper {
  padding: 20px;
  background-color: var(--bl-white-background);
  border-radius: 12px;
}

.leftPanelCollapsed {
  flex: 0 0 100px;
  transition: 0.3s ease all;
}

.pageHeader {
  color: var(--bl-header);
  font-weight: 600;
  margin-bottom: 18px;
  font-size: 24px;
  display: inline-block;
}

.formWrapper .ant-col-12 .ant-select {
  width: 100%;
}

.statusChip {
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 20px;
  line-height: normal;
  padding: 7px 21px;
  align-items: center;
}

.statusChip img {
  margin-right: 4px;
  height: 12px;
  width: 12px;
}

.ant-tag.ant-tag-has-color.archived,
.ant-tag.ant-tag-has-color.archived .anticon-close {
  color: #cc2525;
}

.ant-tag.ant-tag-has-color.completed,
.completed,
.ant-tag.ant-tag-has-color.completed .anticon-close {
  color: #2d912b;
}

.ant-tag.ant-tag-has-color.pending,
.pending,
.ant-tag.ant-tag-has-color.pending .anticon-close {
  color: #645a00;
}

.customAntSelect .ant-select-selector,
.ant-form-item-control-input-content .ant-input {
  color: rgba(0, 0, 0, 0.88);
}

.newFeatureSetBtn {
  display: flex;
  justify-content: end;
  padding: 16px 24px;
  border-top: 1px solid var(--bl-border);
  position: sticky;
  bottom: 0;
  width: calc(100% - 282px);
  background: var(--body-bg-light);
  z-index: 3;
  margin-left: auto;
}

.newAccountSetBtn {
  display: flex;
  justify-content: end;
  padding: 16px 48px;
  border-top: 1px solid var(--bl-border);
  position: sticky;
  bottom: 0;
  width: calc(100% - 282px);
  background: var(--body-bg-light);
}

.addAccountPage:has(.leftPanelCollapsed) .newAccountSetBtn {
  width: calc(100% - 100px);
  margin-left: auto;
}

.audienceSetupPage:has(.leftPanelCollapsed) .newFeatureSetBtn {
  width: calc(100% - 100px);
  margin-left: auto;
}

.setCount {
  background: var(--bl-grey-1);
  font-size: 18px;
  color: var(--bl-white-text);
  width: 29px;
  height: 29px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  vertical-align: top;
  margin-right: 20px;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px 10px 0;
}

.whiteLabelHeading.sectionHeader {
  padding: 0 24px;
}

.sectionHeader h2 {
  margin-bottom: 0;
  font-weight: 600;
  color: var(--bl-header);
}

.formWrapper label,
.ant-form-item-label label {
  color: var(--bl-dark-1);
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 0;
  display: inline-block;
  margin-bottom: 8px;
}

.formWrapper .ant-alert-info {
  background-color: #f6f9fe;
  border: 1px solid #cce2ff;
  border-radius: 6px;
  color: var(--bl-dark-1);
  font-weight: 500;
  margin: 0 15px 10px;
  min-height: 40px;
}

.ant-alert-info .ant-alert-message,
.ant-alert-close-icon span {
  font-weight: 500;
  color: var(--bl-dark-1) !important;
  width: calc(100% - 25px);
}

.ant-alert-info .ant-alert-icon {
  color: var(--bl-primary);
}

.formWrapper .ant-select {
  width: 100%;
}

.ant-slider .ant-slider-track {
  background-color: var(--bl-primary);
}

.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px var(--bl-primary);
  background-color: var(--bl-primary);
}

.customTagsInput {
  border: 1px solid var(--bl-border);
  border-radius: 8px;
  padding: 1px 10px;
}

.react-tagsinput-tag {
  margin-bottom: 0;
  background-color: #f2f4f7;
  border: none;
  border-radius: 25px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 500;
  color: var(--bl-dark-1);
  margin-right: 8px;
}

.react-tagsinput-remove {
  color: var(--bl-dark-1);
}

.formWrapper .ant-form-item {
  margin-bottom: 0;
}

.optionSelection .ant-select .ant-select-arrow {
  font-size: 0;
  background: url(../images/option-selector.svg) no-repeat 3px -3px/20px;
  height: 20px;
  width: 20px;
}

.optionSelection .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--bl-border);
  padding: 6px 23px;
  height: auto;
  margin: 10px 0;
}

.optionSelection .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  color: var(--bl-dark-1);
  font-size: 16px;
  font-weight: 700;
}

.optionSelection {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.optionSelection .anticon.arrow {
  display: block;
  color: var(--bl-primary);
  font-size: 16px;
}

.optionSelection .ant-select-single {
  height: auto;
}

.featureSetWrap.formWrapper {
  padding-left: 68px;
}

.reorderElement {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  content: '';
  background: url(../images/drag.svg) no-repeat center 50%/24px;
  width: 48px;
  cursor: move;
  border-radius: 12px 0 0 12px;
}

.reorderElement:hover {
  background: url(../images/drag-white.svg) var(--bl-primary) no-repeat center 50%/24px;
}

.featureSetWrap {
  position: relative;
}

.loginWrap {
  background: url(../images/login-bg.png) no-repeat center/cover;
  height: 100vh;
  display: flex;
  overflow: auto;
  padding: 50px 0;
  flex-direction: column;
}

.loginContentWrap {
  max-width: 550px;
  margin: auto;
  width: 100%;
}

.loginContent {
  background-color: var(--bl-text);
  border-radius: 12px;
  padding: 48px;
  color: var(--bl-white-text);
}

.loginContent .ant-form-item-label label {
  color: var(--bl-white-text);
  font-size: 14px;
  font-weight: 500;
}

.loginWrap h1 {
  max-width: 305px;
  line-height: 1.2;
  margin-bottom: 36px;
  font-size: 50px;
}

.loginWrap h1 img {
  width: 100%;
}

.loginBtn {
  height: auto;
  margin-left: auto;
  width: 100px;
  display: block;
}

.generateBtn {
  height: auto;
  margin-left: auto;
  width: 150px;
  display: block;
}

.noAcc {
  font-weight: 600;
  font-size: 14px;
  margin-top: 18px;
}

.noAcc a {
  color: var(--bl-primary);
}

.externalLinks {
  max-width: 550px;
  margin: 10px auto;
  display: flex;
  justify-content: end;
}

.externalLinks li a {
  color: var(--bl-secondary);
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}

.externalLinks li:not(:last-child) {
  margin-right: 18px;
}

.saveBtn {
  background-color: var(--bl-white-background);
  padding: 24px;
  position: sticky;
  bottom: 0;
  z-index: 4;
  margin-bottom: 0;
}

.audienceSetupPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.addAccountPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.addAccountPage:has(.leftPanelCollapsed) .addAccountWrap {
  width: calc(100% - 100px);
  margin-left: auto;
}

.audienceListingPageContent,
.audienceActivationTable,
.profileScreenContentWrap {
  padding: 24px;
}

.filterWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
}

.filterWrap .secondaryBtn {
  background-color: transparent;
  margin: 22px 0 0 4px;
  padding: 8px 14px;
}

.filterWrap .ant-select {
  width: 100%;
}

.filterWrap .ant-row {
  max-width: 890px;
  width: 100%;
  flex: 0 0 calc(100% - 145px);
}

.ant-picker .ant-picker-suffix,
.ant-select-suffix {
  color: var(--bl-primary);
}

.antTableElement.ant-table-wrapper .ant-table-thead>tr>th {
  border-bottom: 1px solid var(--bl-border);
  font-weight: 600;
  font-size: 16px;
  color: var(--bl-header);
  padding: 16px;
}

.antTableElement.ant-table-wrapper .ant-table-tbody>tr:not(:last-child)>td {
  border-bottom: 1px solid var(--bl-border);
}

.antTableElement tbody tr td:first-child {
  color: var(--bl-dark-1);
  font-weight: 500;
}

.antTableElement tbody tr td:not(a) {
  color: var(--bl-header);
}

.actionEdit {
  color: #667085;
  font-weight: 600;
}

.actionDelete {
  color: var(--bl-delete);
  font-weight: 600;
}

.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border: 1px solid var(--bl-border);
  border-radius: 2px;
}

.ant-pagination .ant-pagination-item-active {
  border: 1px solid var(--bl-primary);
  color: var(--bl-primary);
}

.leftPanelMainMenu li {
  display: flex;
  align-items: center;
}

.ant-spin .ant-spin-dot-item {
  background-color: var(--bl-primary);
}

.npiDisplayElemBin {
  color: var(--bl-delete);
}

.sectionHeader .npiDisplayElemBin {
  font-size: 18px;
  margin: 0 0 0 12px;
}

.sectionHeader>div {
  display: flex;
  align-items: center;
}

.elementLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteIconForElements {
  cursor: pointer;
  padding-right: 4px;
  color: var(--bl-delete);
}

.refreshIcon {
  opacity: 0.6;
  cursor: pointer;
}

.featureSetWrap .ant-alert-action {
  height: 18px;
}

.ant-table-wrapper .ant-table-tbody>tr>td,
.ant-table-wrapper .ant-table-thead>tr>th {
  padding: 8px 16px;
}

.modalGenericContent {
  display: flex;
}

.modalGenericTextContent {
  padding-left: 10px;
  font-weight: 400;
  width: 100%;
}

.modalGenericTextContent p:first-child {
  font-weight: 500;
}

.errorScreenWrap {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorScreenWrap h1 {
  margin-top: 50px;
  font-size: 4rem;
  margin-bottom: 15px;
}

.errorScreenWrap p {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--bl-dark-1);
}

.errorScreenWrap .primaryBtn {
  margin-top: 20px;
  border: 1px solid var(--bl-primary);
  color: var(--bl-primary);
  background-color: transparent;
}

.errorScreenWrap .primaryBtn:hover {
  background-color: var(--bl-primary);
  color: var(--bl-white-text);
}

.left-panel-header img {
  margin-top: -10px;
}

.actionIcon {
  width: 16px;
  height: 19px;
  font-size: 15px;
  line-height: 1;
  cursor: pointer;
}

.actionArea {
  align-items: center;
}

.actionArea .ant-space-item {
  width: 16px;
  height: 19px;
}

.noStyleBtn {
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid var(--bl-border);
  background: transparent;
  font-size: 14px;
}

.noStyleBtn:hover {
  border-color: var(--bl-primary);
  color: var(--bl-primary);
}

.actionArea .ant-space-item:nth-child(3) span {
  color: #027a48;
}

.npiDisplayElem>span:nth-child(2) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}

.audienceSizeTextBox {
  width: 350px;
}

.audienceActivation .selectWrap>span:first-child {
  color: var(--bl-dark-1);
  margin-right: 10px;
}

.audienceActivation .selectWrap .ant-select {
  min-width: 300px;
}

.audienceActivation .ant-tabs-content-holder {
  padding: 5px 0 15px;
}

.pixelListingTable .actionArea .ant-space-item:last-child span {
  color: var(--bl-delete);
}

.inlineLabelElem {
  align-items: center;
  margin-bottom: 20px;
}

.inlineLabelElem .ant-input-affix-wrapper {
  margin-left: 17px;
  max-width: calc(100% - 40px);
}

.informationText {
  margin-top: 10px;
}

.inlineLabelElem .ant-input-suffix {
  cursor: pointer;
}

.selectBtn.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  padding: 4px 36px;
}

.selectBtn.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector input {
  position: absolute;
  bottom: 0;
  height: 40px;
}

.selectBtn.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  padding-inline-end: 0;
  color: var(--bl-dark-1);
}

.targetBlock {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.targetBlock>*:not(:last-child),
.checkboxWrapToGenerateResearch {
  margin-right: 10px;
}

.targetBlock .elementLabel {
  margin-bottom: 0;
  width: 100px;
  justify-content: start;
}

.targetBlockWrap>*:first-child {
  margin-top: 24px;
}

.checkboxWrapToGenerateResearch label {
  display: flex;
  flex: 0 0 50%;
}

.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).selectBtn .ant-select-selector {
  border-color: var(--bl-border);
  box-shadow: none;
}

.formWrapper .ant-select.multiSelectDropdown {
  width: 330px;
}

.checkboxWrapToGenerateResearch {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.createResearchElementsWrap .checkboxListWrapper {
  margin-left: auto;
}

.addResearchElemHeader {
  width: 100%;
}

.leftPanelMenu>li:not(:first-child):not(:last-child) img {
  filter: grayscale(1);
}

.leftPanelMenu>li:not(:first-child):not(:last-child).ant-menu-item-selected img {
  filter: grayscale(0);
}

.leftPanelMenu>li.ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):not(:last-child):hover {
  color: var(--bl-primary);
  background-color: transparent;
}

.pageHeaderWrap {
  padding: 24px;
  border-bottom: 1px solid var(--bl-secondary);
  position: sticky;
  top: 0;
  z-index: 9;
  background: var(--body-bg-light);
}

.ant-select-multiple .ant-select-selector {
  padding-block: 4px;
  border-radius: 8px;
}

.ant-table-content {
  border: 1px solid var(--bl-border);
  border-radius: 8px;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  background-color: var(--bl-white-background);
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.audienceListingPageContent .filterWrap {
  margin-top: 0;
}

.audienceListingPageContent .filterWrap div:last-child.ant-col-8>* {
  margin-right: 0;
  width: auto;
}

.ant-input-affix-wrapper {
  padding: 7px 11px;
}

.addResearchElemHeader .ant-select-selector,
.sectionHeader .checkboxListDropdown .ant-select-selector {
  padding: 0;
}

.ant-table-wrapper .ant-table {
  border-radius: 8px;
}

.checkboxListWrap .ant-checkbox-wrapper {
  color: var(--bl-header);
}

.defaultFeatureSetSection {
  margin: 20px auto;
}

.defaultFeatureSetSection .choosePixelSelect {
  width: 100%;
}

.defaultFeatureSetSection>div {
  margin-bottom: 16px;
}

.newFeatureSetBtn>*:not(:last-child) {
  margin-right: 15px;
}

.newFeatureSetBtn .ant-space-compact-block {
  width: auto;
}

.featureSetWrapElement,
.featureSetHandleWrap {
  background-color: var(--bl-light-grey);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 24px;
  border: 1px solid var(--bl-border);
}

.featureSetHeadingWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  color: var(--bl-grey-1);
  font-size: 16px;
  height: 24px;
}

.noStyleDropdown,
.noStyleDropdown.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: transparent;
  box-shadow: none;
  padding: 0;
  color: var(--bl-grey-1);
  background-color: transparent;
}

.featureSetHeadingWrap>div img {
  margin-right: 8px;
  cursor: move;
  color: var(--bl-grey-1);
}

.featureSetWhole {
  margin-bottom: 16px;
}

.addNewFeaturesetbtn button {
  display: flex;
}

.featureSetHandleWrap {
  padding: 12px 16px;
  margin: 16px 0;
  max-width: 300px;
  position: relative;
  z-index: 1;
  display: inline-block;
}

.featureSetHandleWrap>button {
  color: var(--bl-header);
  font-size: 14px;
  transition: 0.3s ease all;
}

.featureSetHandleWrap .noStyleDropdown {
  padding: 5px 16px;
  border-radius: 100px;
}

.noStyleDropdown.activeFeatureSetHandleOption {
  background-color: var(--bl-blue-light);
  font-weight: 600;
}

.featuresetElementSingle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
}

.featureSetElementSingleLeft {
  flex: 0 0 90%;
  display: flex;
}

.featureSetElementSingleLeft .ant-select {
  width: 100%;
}


.dualSelectorWrapper .ant-col {
  width: 50%;
  display: flex;
}

.dualSelectorWrapper .ant-col:not(:last-child) {
  padding-right: 10px;
}

.featureSetWholeWrapBlock {
  width: 100%;
  margin: 0 48px 0 0;
  padding: 24px;
}

.primaryBtn.loginBtn.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--bl-white-text);
  border-color: var(--bl-white-text);
}

.audienceListDateRangeFilterWrap .eachCommonElementWrapper {
  width: 100%;
}

.audienceListDateRangeFilterWrap>div:not(:last-child) {
  padding-right: 10px;
}

.dualSelectorWrapper {
  width: 100%;
}

.leftPanelMenu>li:not(:first-child):not(:last-child):hover img {
  filter: grayscale(0);
}

.audienceListTable.antTableElement.ant-table-wrapper .ant-table-tbody>tr>td a,
.antTableElement.researchListTable.ant-table-wrapper .ant-table-tbody>tr>td a,
.commonAudienceTableWrap .ant-table-wrapper .ant-table-tbody>tr>td a {
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 3px 11px;
  height: auto;
}

.featureSetHeadingWrap>div:first-child {
  display: flex;
  align-items: center;
}

.breadCrumbContainer {
  display: flex;
  align-items: center;
}

.breadCrumbLink {
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.customAntSelect.ant-select .ant-select-arrow {
  color: var(--bl-primary);
}

.breadCrumbSeparator {
  margin: 4px 5px 0 5px;
  display: inline-block;
}

.breadCrumbSeparator img {
  width: 14px;
}

.breadCrumbContainer>span {
  display: flex;
  align-items: center;
}

.breadCrumbContainer>span>span>button {
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 0.75rem;
}

.loginContent .ant-input {
  padding: 7px 11px;
}

.loginContent .rules {
  color: var(--bl-white-text);
  margin-top: 10px;
  margin-bottom: 10px;
}

.loginContent .rules ul li .tick {
  color: var(--bl-icon-light);
}

.loginContent .rules ul li .cross {
  color: var(--bl-icon-red);
}

.checkboxListDropdown .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

.leftPanelMenu li:last-child.ant-menu-item li:hover span {
  color: var(--bl-primary);
}

.actionMenuIcon {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.antTableElement.researchListTable tbody tr td:first-child {
  font-weight: 600;
}

.pixelListingTable.antTableElement tbody tr td:first-child,
.antTableElement.pixelListingTable tbody tr td:nth-child(3) {
  font-weight: 400;
}

.calendarExtraFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
}

.calendarExtraFooter .noBGBtn {
  font-weight: 500;
  padding: 10px 12px;
}

.calendarExtraFooter>div button:first-child {
  margin-right: 8px;
}

.ant-picker-dropdown .ant-picker-footer:has(.calendarExtraFooter) {
  border-top: none;
}

.datePickerPopup .ant-picker-panel-container {
  background-color: var(--body-bg-light);
}

.datePickerPopup .ant-picker-header button {
  color: var(--bl-header);
}

.ant-picker-dropdown.datePickerPopup .ant-picker-cell .ant-picker-cell-inner {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  color: var(--bl-header);
}

.ant-picker-dropdown.datePickerPopup .ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 50%;
}

.ant-picker-dropdown.datePickerPopup .ant-picker-cell:not(.ant-picker-cell-in-view) {
  color: var(--bl-grey-1);
}

.ant-picker-dropdown.datePickerPopup .ant-picker-header {
  border-bottom: none;
  padding: 10px 8px 0 8px;
}

.datePickerWrapper.ant-picker .ant-picker-suffix .anticon-calendar {
  color: var(--bl-grey-1);
}

.createResearchElementWrap .targetBlock {
  width: 100%;
}

.ant-select-selection-overflow-item-rest span {
  background-color: var(--bl-primary) !important;
  color: var(--bl-white-text);
  border-radius: 20px;
}

button.addFeatureSetCTA {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  flex: 0 0 40px;
  margin: 0 5px;
  border: 1px solid var(--bl-border);
  color: var(--bl-grey-1);
  background-color: var(--bl-light-grey);
}

.featureSetElementSingleLeft .elementLabel {
  margin-bottom: 0;
}

.featureSetElementSingleLeft .anticon-delete {
  margin-left: 5px;
}

.rightChartWrap {
  flex: 0 0 25%;
  position: fixed;
  right: 0;
  transition: 0.3s ease-in all;
  width: 300px;
  background-color: var(--body-bg-light);
  border-left: 1px solid var(--bl-border);
  height: calc(100% - 174px);
  overflow: auto;
  z-index: 3;
}

.rightChartWrap.collapsedRightPanel {
  width: calc(100% - 280px);
}

.rightChartWrap.collapsedRightPanel p:first-child span.anticon {
  transform: rotate(-180deg);
  margin-right: 5px;
}

.rightChartWrap p:first-child {
  margin: 20px 0 20px 16px;
  color: var(--bl-grey-1);
}

.infoElemWrap>div {
  border: 1px solid var(--bl-border);
  border-radius: 8px;
  background-color: var(--bl-white-background);
  margin-bottom: 16px;
  padding: 15px;
  width: 97%;
  height: auto;
}

.chartElemWrap>div {
  border: 1px solid var(--bl-border);
  border-radius: 8px;
  background-color: var(--bl-white-background);
  margin-bottom: 16px;
  padding: 15px;
  width: 250px;
  height: 252px;
}

.chartElemWrap,
.infoElemWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rightChartWrap p:first-child svg {
  margin-right: 8px;
}

.rightChartWrap.collapsedRightPanel .chartElemWrap {
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px 24px 24px;
}

.rightChartWrap.collapsedRightPanel .chartElemWrap>div {
  width: 32% !important;
  height: 340px !important;
}

.leftPanelCollapsed~.audienceCommonWrap .rightChartWrap.collapsedRightPanel {
  width: calc(100% - 100px);
}

.eachCommonElementWrapper:has(.checkboxListDropdownElem),
.eachCommonElementWrapper:has(.pixelSelectSSPDropdown),
.eachCommonElementWrapper:has(.measurementPixelDropdown) {
  width: 100%;
}

.measurementPixelDropdown {
  min-width: 100%;
}

.ant-modal .ant-modal-content {
  padding: 20px;
}

.ant-modal-footer button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: var(--bl-primary);
  font-weight: 600;
}

.ant-input-outlined:has(input:read-only):hover,
.ant-input-outlined:has(input:read-only):focus-within {
  border-color: var(--bl-border);
  box-shadow: none;
}

.ant-input-outlined:has(input:read-only),
input.ant-input-outlined:read-only {
  background-color: rgba(0, 0, 0, 0.04);
  color: var(--bl-grey-2);
  border-color: #d9d9d9;
  box-shadow: none;
}

.copyAction {
  cursor: pointer;
}

.ant-input-outlined input:read-only {
  font-family: 'Roboto Mono', monospace;
}

.newFeatureSetBtn:has(.responsiveCTAForRightPanel) {
  align-items: center;
  justify-content: space-between;
}

.editAudienceNewFeatureSetBtn {
  display: flex;
}

.audienceEstimateWrap h2 {
  margin-bottom: 15px;
  font-size: 18px;
  color: var(--bl-header);
}

.audienceEstimateWrap {
  margin-bottom: 20px;
}

.audienceEstimateElementWrap {
  margin-top: 15px;
}

.audienceEstimateElementWrap p {
  margin-bottom: 5px;
}

.responsiveCTAForRightPanel {
  border: none;
  visibility: hidden;
  cursor: pointer;
}

.editAudienceNewFeatureSetBtn button:not(:last-child):not(.dropdownSaveButton button) {
  margin-right: 8px;
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 0;
  color: var(--bl-white-text);
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-close {
  color: var(--bl-white-text);
}

.globalFeatureSetWrapElement {
  margin-bottom: 16px;
  background-color: #f2f0eb;
}

.secondaryBtn.backBtn {
  background-color: var(--bl-blue-light-2);
}



.chart1 b {
  display: block;
}

.chart1 b:first-child {
  color: var(--bl-grey-1);
  font-size: 10px;
}

.chart1 b:nth-child(2) {
  color: var(--bl-header);
  font-size: 30px;
}

.chart1 b:nth-child(2) span {
  font-size: 14px;
}


.dropdownSaveButton button:nth-child(2) .ant-btn-icon img {
  margin-top: 5px;
}

.pixelUrlSec {
  border: 1px solid var(--bl-border);
  border-radius: 8px;
  margin: 24px;
  background-color: var(--bl-white-background);
}

.pixelUrlSec .informationText .elementLabel {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}

.newFeatureOptions {
  flex: 0 0 130px;
  width: 130px;
}

.createResearchElementWrap>div:not(:last-child) .multiSelectDropdown {
  max-width: 435px;
  flex: 0 0 435px;
}

.createResearchElementWrap>div:last-child .multiSelectDropdown {
  max-width: 590px;
}

.ant-select .ant-select-arrow {
  font-size: 0;
  background: url(../images/Chev-Down-blue.svg) no-repeat center/24px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  margin-top: 0;
  top: 20px;
}

.ant-slider .ant-slider-mark-text {
  font-size: 10px;
}

.updateResearchContentWrap>*:not(:first-child) {
  padding: 24px 0;
}

.updateResearchContentWrap {
  max-width: 780px;
}

.topAligned {
  vertical-align: top;
}

.bottomAligned {
  vertical-align: bottom;
  color: var(--bl-grey-1);
}

.partnerList li {
  color: var(--bl-grey-1);
}

.audienceDestinationModal .modalGenericTextContent>div {
  margin-bottom: 10px;
}

.audienceDestinationModal h3,
.audienceDestinationModal h4,
.audienceDestinationModal .modalGenericTextContent div.ant-row {
  margin-bottom: 20px;
}

.audienceDestinationModal .modalGenericTextContent {
  max-width: calc(100% - 20px);
  width: 100%;
  margin-bottom: 22px;
}

.partnerSettingsInputWrap {
  border: 1px solid var(--bl-border);
  padding: 10px;
  border-radius: 6px;
}

.sectionHeader.audienceEstimate {
  margin-right: 0;
}

.audienceDestinationModal .modalGenericTextContent div.ant-row .elementLabel {
  margin-bottom: 0;
}

.audienceDestinationModal .modalGenericTextContent div.ant-row .ant-space {
  cursor: pointer;
}

.bottomAligned span {
  display: block;
}

.emptyTableDataWrap img {
  width: 50px;
  margin-bottom: 5px;
}

.emptyTableDataWrap {
  padding: 10px 0;
  display: block;
}

.activationTableWrap {
  margin-top: 20px;
}

.lessOpacityText {
  opacity: 0.5;
}


.multiSelectDropdown .ant-select-selector {
  min-height: 38px;
  max-height: 200px;
  overflow: auto;
}

.multiSelectDropdown .ant-select-selector::-webkit-scrollbar-track {
  pointer-events: none;
}

.multiSelectDropdown {
  border-radius: 8px;
}

.ant-tag.activated {
  background-color: rgb(236, 253, 243);
  color: rgb(45, 145, 43);
}

.activated.ant-tag.ant-tag-has-color .anticon-close {
  color: rgb(45, 145, 43);
}

.ant-tag.building,
.building.ant-tag.ant-tag-has-color .anticon-close {
  color: rgb(100, 90, 0);
}

.ant-tag.estimated,
.estimated.ant-tag.ant-tag-has-color .anticon-close {
  color: rgb(2, 106, 162);
}

.leftPanelProfileMenu li {
  cursor: pointer;
  height: 30px;
}

.leftPanelProfileMenu li:hover {
  color: var(--bl-primary);
}

.userSettingsPopup {
  background-color: var(--bl-primary);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bl-white-text);
  font-weight: 600;
  margin-left: 32px;
  flex-direction: column;
}

.leftPanelWrap .ant-menu .ant-menu-item {
  border-radius: 0;
}

.leftPanelWrap .ant-menu .ant-menu-item:has(.userSettingsPopup) {
  width: auto;
}

ul.leftPanelProfileMenu {
  background: var(--bl-white-background);
  left: 80px;
  position: fixed;
  bottom: 20px;
  right: inherit;
  width: 150px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.75) 10px 9px 33px -22px;
  padding: 5px 10px;
}

.leftPanelProfileMenu li img {
  padding-right: 5px;
  filter: grayscale(1);
}

.leftPanelProfileMenu li:hover img {
  filter: grayscale(0);
}

.leftPanelMenu .ant-menu-submenu-title {
  padding-left: 16px !important;
}

.leftPanelMenu .ant-menu-submenu-title img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.ant-menu-inline-collapsed.leftPanelMenu>.ant-menu-submenu>.ant-menu-submenu-title {
  padding-inline: 0;
  padding-left: 0 !important;
  line-height: 56px;
}

.ant-menu.leftPanelMenu .ant-menu-submenu-title .ant-menu-item-icon+span,
.ant-menu-light.leftPanelMenu .ant-menu-item {
  margin-inline-start: 0;
  padding-left: 0;
  color: var(--bl-grey-1);
}

.ant-menu-light.leftPanelMenu .ant-menu-item .leftPanelProfileMenu {
  color: var(--bl-header);
}

.ant-menu.leftPanelMenu .ant-menu-submenu-arrow {
  color: var(--bl-grey-1);
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
  background-color: transparent;
  color: var(--bl-primary);
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover .ant-menu-item-icon+span,
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover .ant-menu-submenu-arrow,
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  color: var(--bl-primary);
  background-color: transparent;
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}

.ant-menu-inline.ant-menu-sub .ant-menu-item>.ant-menu-title-content {
  padding-left: 28px;
}

.ant-menu.leftPanelMenu>li:not(:first-child).ant-menu-item .ant-menu-item-icon {
  width: 16px;
  height: 16px;
}

.multipleInputsInARow,
.multipleInputsInARow>div {
  display: flex;
}

.modalGenericContent .ant-select-single {
  height: auto;
}

.modalGenericTextContent div>div.ant-row {
  margin-bottom: 10px;
}

.multipleInputsInARow {
  margin-bottom: 10px;
  width: calc(100% - 18px);
}

.multipleInputsInARow .commonInputFieldBodyWrapper {
  margin-right: 10px;
}

.commonModal:not(~ .audienceDestinationModal) .elementLabel {
  margin-bottom: 0;
}

.multipleInputsInARow>input {
  margin-right: 10px;
}

.multipleInputsInARow .eachCommonElementWrapper {
  width: calc(100% - 18px);
}

.alertSpecificClass .ant-modal-content {
  background: rgba(20, 20, 20, 0.9);
  color: var(--bl-text-light);
}

.alertSpecificClass .modalGenericTextContent p:first-child {
  font-weight: 600;
  margin-bottom: 7px;
}

.alertSpecificClass .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
.alertSpecificClass .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: transparent;
  color: var(--bl-white-text);
}

.alertSpecificClass .ant-btn {
  padding: 4px;
}

.multipleInputsInARow .multipleInputSecSecondElem {
  flex: 0 0 180px;
}

.timeframeClass {
  width: 220px;
}

.activatedToInput {
  width: 100%;
}

.globalFilterModal>div p:first-child {
  margin-bottom: 20px;
  margin-top: 15px;
}

.globalFilterModal .elementLabel {
  flex: 0 0 100px;
}

.globalFilterModal .eachCommonElementWrapper {
  width: calc(100% - 130px);
}

.globalFilterModal .elementLabel.customTimeframeLabel {
  flex: 0 0 100px;
}

.customTimeframeLabel~.eachCommonElementWrapper {
  width: calc(100% - 130px);
}

.accountListingContent .filterWrap {
  margin-top: 0;
}

.accountSettingsNewField div:first-child {
  display: flex;
  width: 100%;
}

.accountSettingsNewField .commonInputFieldBodyWrapper {
  width: 100%;
}

.aboveTableAction {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  height: 40px;
}

.agencyBrandAdvertiser {
  margin-top: 30px;
}

.aboveTableAction p:first-child {
  font-weight: 500;
  font-size: 1.25rem;
}

.genericAddingRecordModal .ant-modal-body>div,
.profileDetailsForm .ant-row .ant-col {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.genericAddingRecordModal .elementLabel {
  flex: 0 0 70px;
}

.genericAddingRecordModal .ant-modal-body>div>div:nth-child(2) {
  width: calc(100% - 18px);
}

.genericAddingRecordModal .ant-modal-body h3 {
  margin-bottom: 25px;
}

.textHyperLink {
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 3px;
  color: #1677ff;
  cursor: pointer;
}

.profileAvatarContentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profilePicBtnPri {
  margin-top: 20px;
  min-width: 300px;
  display: flex;
  justify-content: center;
}

.profileAvatarBtnSec {
  margin-top: 20px;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
}

.accountSettingsNewField .elementLabel {
  flex: 0 0 100px;
}

input.ant-input,
.audienceSizeInput .ant-input-number-input {
  padding: 7px 10px;
}

.profileDetailsForm {
  margin-top: 24px;
}

.profileDetailsForm .ant-row .ant-col {
  margin-bottom: 20px;
}

.profileAvatarContentWrap p {
  font-weight: 600;
  margin-top: 10px;
  font-size: 20px;
}

.profileDetailsForm .elementLabel {
  flex: 0 0 100px;
}

.genericAddingRecordModal .ant-form-item {
  margin-bottom: 16px;
}

.disabledElem {
  cursor: not-allowed;
  opacity: 0.5;
}

.antTableElement tbody tr:has(.disabledElement),
.antTableElement tbody tr:has(.archivedText),
.ant-table-wrapper tr:has(.archivedText) td.ant-table-column-sort,
.ant-table-wrapper .ant-table-tbody tr:has(.archivedText)>.ant-table-cell-row-hover,
.ant-table-wrapper .ant-table-tbody .ant-table-row:has(.disabledElement)>.ant-table-cell-row-hover {
  background-color: #e9e9e9;
}

.profileAvatarContentWrap img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--bl-text);
}

.ant-upload .ant-btn {
  height: 40px;
}

.profilePicBtnPrimary {
  margin-top: 20px;
}

.profileDetailsForm .ant-form-item-control-input {
  width: 100%;
}

.profileDetailsForm .ant-form-item-row div:first-child.ant-col {
  flex: 0 0 80px;
}

.profileDetailsForm .ant-form-item {
  margin-bottom: 0;
}

.loginContent h2 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.leftPanelMenu .userSettingsPopup img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.measurementHeading {
  font-weight: 600;
  color: var(--bl-header);
}

.measurementPixelDropdown.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}

.audienceActivationPagePixel {
  padding: 24px;
  margin: 0;
  border: 1px solid var(--bl-border);
  border-radius: 8px;
}

.filteredByText>p:first-child {
  margin-right: 4px;
  font-weight: 600;
}

.filterBtnAndText {
  display: flex;
  align-items: center;
  flex: 0 0 calc(100% - 150px);
}

.filterWrap .ant-btn,
.filterWrap .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: 0;
}

.filteredByText {
  margin-left: 8px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.noRightSideCTA.filterWrap {
  justify-content: start;
}

.filterWrap .ant-btn {
  padding: 0;
  flex: 0 0 30px;
}

.filterWrap .ant-btn img {
  filter: brightness(0%);
  transition: 0.2s all;
}

.filterWrap .ant-btn:hover img {
  filter: brightness(100%);
}

.ant-menu-light .ant-menu-submenu .ant-menu-item:not(:last-child).ant-menu-item-selected {
  background: none;
  box-shadow: none;
  border-radius: none;
  color: var(--bl-primary);
}

.ant-menu-inline.ant-menu-sub .ant-menu-item.ant-menu-item-selected>.ant-menu-title-content {
  background: var(--bl-white-background);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  color: var(--bl-primary);
}

.infoElemWrap .chart1 h2 {
  color: var(--bl-header);
  font-weight: 600;
  font-size: 0.875rem;
  padding-left: 5px;
}

.infoElemWrap .chart1 p {
  color: var(--bl-header);
  font-size: 0.875rem;
  margin-top: 5px;
}

.infoElemWrap .chart1 .d-flex {
  align-items: center;
}

.ant-menu-vertical.leftPanelMenu .ant-menu-submenu-title {
  width: 100%;
  margin-inline: 0;
}

.ant-menu-inline.leftPanelMenu .ant-menu-submenu-title {
  margin-inline: 0;
}

.filteredItems {
  align-items: center;
  display: flex;
  justify-content: space-around;
  border-radius: 15px;
  width: 110px;
  height: 30px;
  background-color: var(--bl-primary);
  color: var(--bl-white-text);
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
}

.filteredItems button {
  background-color: transparent;
  border: none;
  margin-left: 5px;
  color: var(--bl-white-text);
}

.ant-table-wrapper.antTableElement .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table-wrapper.antTableElement .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
  border-inline-end: none;
}

.leftPanelMenu>li:not(:first-child):not( :last-child).ant-menu-submenu.ant-menu-submenu-inline {
  padding: 5px 0;
}

.newUserCTA button {
  margin-left: auto;
}

.measuremntPixelSec.pixelUrlSec {
  margin: 0;
  padding: 24px;
}

.audienceListTable.antTableElement.ant-table-wrapper .ant-table-tbody>tr>td a h4,
.partnerList li {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 190px;
}

.loginForm div:nth-child(2).ant-form-item {
  margin-bottom: 10px;
}

.forgotPass {
  cursor: pointer;
  color: var(--bl-white-text);
  text-decoration: underline;
}

.errorText {
  background: #fbd5d5;
  padding: 8px;
  color: #cc2525;
  border-radius: 4px;
  margin-bottom: 7px;
}

.audienceScreenChartWrap .chartElemWrap {
  flex-direction: row;
  justify-content: space-between;
  max-width: 800px;
  margin: auto;
}

.audienceScreenInfoElemWrap.infoElemWrap>div {
  width: 100%;
}

.genericAddingRecordModal .ant-modal-body label {
  height: auto;
}

.ant-tag {
  margin-inline-end: 0;
}

.ant-tag .ant-tag-close-icon {
  margin-inline-start: 4px;
}

.audienceSizeInput {
  position: relative;
}

.modalGenericTextContent h3 {
  color: var(--bl-header);
  padding-bottom: 10px;
}

.helpTextAudienceSize {
  font-size: 10px;
  color: var(--bl-grey-1);
  padding-top: 5px;
}

.defaultFeatureSetSection .ant-select-single {
  height: auto;
}

.generateEstimateBtn {
  display: flex;
  align-items: center;
}

p.audienceAge {
  color: #645a00;
  margin-bottom: 20px;
  font-weight: 400;
  padding: 5px 20px;
  background-color: var(--bl-yellow);
  display: inline-block;
  border-radius: 25px;
}

.checkboxListWrap {
  display: flex;
  align-items: center;
}

.checkboxListWrap .ant-switch {
  margin-right: 7px;
}

.ant-switch.ant-switch-small {
  min-width: 40px;
  height: 20px;
  line-height: 20px;
}

.ant-switch.ant-switch-small .ant-switch-handle {
  width: 16px;
  height: 16px;
}

.ant-switch.ant-switch-small.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 18px);
}

.ant-select-single {
  height: auto;
}

.impressionPixelModal .modalLabel {
  margin-bottom: 5px;
}

.labelAndCTA {
  align-items: center;
  justify-content: space-between;
}

.labelAndCTA button:not(.primaryBtn) {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: var(--bl-grey-1);
  font-weight: 600;
  font-size: 0.75rem;
  margin: 0 0 5px 0;
}

.impressionPixelModal .informationText .elementLabel {
  font-size: 0.875rem;
  margin-bottom: 10px;
  font-weight: 400;
}

.impressionPixelModal .informationText .elementLabel>div {
  line-height: 1.3;
  padding-right: 5px;
}

.elementLabel>div {
  line-height: 15px;
}

img.careGiverIcon {
  width: 20px;
  height: auto;
  margin-left: 5px;
  opacity: 0.4;
}

.audience_type:has(.careGiverIcon) {
  display: flex;
  align-items: center;
}

.bannerForDev {
  background-color: rgb(204, 37, 37);
  color: white;
  padding: 10px 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 99;
}

.bannerForDev p {
  margin: 0;
}

.leftPanelWrap.leftPanelDev {
  height: calc(100% - 41px);
}

.audienceDestinationModal h3 {
  font-size: 1.25rem;
  margin-bottom: 15px;
}

.audienceDestinationModal .modalHeader p {
  font-weight: 600;
  margin-bottom: 15px;
}

.audienceDestinationModal .modalHeader {
  margin-bottom: 4px;
}

.adminoptionAccordion .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-header-text {
  display: flex;
  font-size: 0.875rem;
}

.adminoptionAccordion .ant-collapse.ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
  padding-inline-end: 12px;
  transform: rotate(90deg);
  padding-top: 28px;
  padding-bottom: 26px;
}

.adminoptionAccordion .ant-collapse.ant-collapse-icon-position-end>.ant-collapse-item-active>.ant-collapse-header .ant-collapse-expand-icon {
  transform: scaleY(-1);
}

.adminoptionAccordion .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-header-text .audienceDefinitionleft {
  padding: 16px 24px;
}

.adminoptionAccordion .ant-collapse>.ant-collapse-item.ant-collapse-item-active>.ant-collapse-header .ant-collapse-header-text .audienceDefinitionleft {
  background-color: transparent;
  border: none;
}

.adminoptionAccordion .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 0;
  align-items: center;
}

.adminOptionElem,
.adminOptionInnerElem {
  display: flex;
  align-items: center;
}

.adminOptionElem p:first-child,
.adminOptionInnerElem:first-child p:first-child {
  flex: 0 0 240px;
  padding-right: 8px;
}

.adminOptionElem:first-child {
  margin-bottom: 20px;
}

.adminOptionInnerElem:nth-child(2) {
  margin-left: 24px;
}

.adminOptionInnerElem:nth-child(2) p:first-child {
  flex: 0 0 80px;
}

.adminOptionElem .ant-input-number {
  width: auto;
}

.audienceSizeInput .ant-input-number {
  width: 50%;
}

.adminoptionAccordion.pixelUrlSec {
  margin: 0 0 24px 0;
}

.adminoptionAccordion .ant-collapse-header-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--bl-header);
}

.updatedDataInfo {
  margin-top: 16px;
  color: var(--bl-grey-1);
}

.audienceDefinitionElem {
  display: flex;
  justify-content: start;
  align-items: stretch;
  background: #ffffff;
  border: 1px solid var(--bl-border);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 16px;
}

.audienceDefinitionleft p {
  color: var(--bl-header);
  font-weight: 600;
  max-width: 160px;
}

.audienceDefinitionleft,
.adminoptionAccordion .ant-collapse-borderless>.ant-collapse-item:last-child .ant-collapse-header {
  background-color: var(--body-bg-light);
  border-right: 1px solid var(--bl-border);
  border-radius: 8px 0 0 8px;
  flex: 0 0 190px;
  align-items: start;
}

.adminoptionAccordion .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding-top: 16px;
}

.audienceDefinitionElem>div {
  padding: 16px 24px;
}

.audienceDefinitionWrap h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--bl-header);
  margin-bottom: 16px;
}

.audienceDefinitionElem input::placeholder,
.ant-select-selector input::placeholder {
  color: var(--bl-grey-1);
}

.intermediateContent {
  display: inline-block;
  border: 1px solid var(--bl-border);
  border-radius: 8px;
  padding: 9px 16px;
  background: var(--body-bg-light);
  margin-left: 210px;
  margin-bottom: 16px;
  position: relative;
}


.pipe {
  position: absolute;
  top: -22px;
  left: 30px;
  border-right: 1px solid #8E8E8E;
  height: 75px;
  display: inline-block;
  z-index: -1;
}

.patientHealthAttributeElem .pipe {
  left: 60px;
}

.addNewFeaturesetbtn.addPatientHealthAttribute {
  position: relative;
}


.addPatientHealthAttribute .pipe {
  left: 18px;
  height: 40px;
}

.patientHealthAttributeElem {
  width: calc(100% - 90px);
  margin-left: auto;
  position: relative;
}


.addPatientHealthAttribute .ant-btn {
  height: 37px;
  width: 37px;
  margin-left: 133px;
  padding: 0;
  background-color: var(--body-bg-light);
}

.patientHealthAttributeElem::before {
  left: 60px;
}

.patientHealthAttributeElem .audienceDefinitionleft {
  width: 175px;
}

.audienceDefinitionWrap .ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
  color: var(--bl-header);
}

.audienceDefinitionRight .labelAndValue {
  display: flex;
}

.audienceDefinitionRight .ant-select-single {
  flex: 0 0 190px;
  margin-right: 8px;
}

.audienceDefinitionRight .ant-select-multiple {
  flex: 0 0 calc(100% - 190px);
}

.audienceDefinitionRight {
  flex: 0 0 calc(100% - 190px);
}

.audienceDefinitionRight .ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item {
  border-radius: 12px;
  padding-inline-end: 20px;
  color: rgba(0, 0, 0, 0.88);
}

.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.88);
}

.audienceDefinitionRight .ant-select-multiple .ant-select-arrow {
  display: none;
}

.audienceDefinitionRight .ant-select-multiple .ant-select-selector {
  padding: 2px 10px;
}

.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-remove>.anticon,
.debounceDDTagWrapper .ant-tag-close-icon {
  font-size: 0;
  background: url(../images/close.svg) no-repeat center/24px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
}

.searchPartnerWrap {
  display: flex;
  align-items: flex-end;
  max-width: calc(100% - 20px);
}

.searchPartnerWrap>* {
  flex: 0 0 50%;
}

.searchPartnerWrap .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  height: 38px;
}

.searchPartnerWrap>*:first-child {
  margin-right: 16px;
}

.partnerImg {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partnerBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 146px;
  width: 146px;
  border: 1px solid var(--bl-border);
  background-color: var(--body-bg-light);
  border-radius: 8px;
  margin-bottom: 10px;
}

.partnerDataSelectWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-height: 390px;
  overflow: auto;
  margin-top: 34px;
}

.partnernameAndStatus {
  text-align: center;
  padding: 0 5px
}

.partnerDataSelectWrap .ant-radio-wrapper {
  position: relative;
}

.partnerDataSelectWrap .ant-radio-wrapper .ant-radio {
  position: absolute;
  top: 7px;
  right: 7px;
  border-radius: 0;
}

.partnerDataSelectWrap .ant-radio-wrapper .ant-radio-inner {
  opacity: 0;
}

.partnerDataSelectWrap .ant-radio-wrapper .ant-radio-checked {
  background: url(../images/radio-checked.svg) no-repeat center/16px;
  height: 16px;
  width: 16px;
}

.partnerDataSelectWrap .ant-radio-wrapper span.ant-radio+* {
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.partnernameAndStatus h4 {
  color: var(--bl-header);
  margin-bottom: 0;
  line-height: 1.3;
}

.partnernameAndStatus p {
  color: var(--bl-grey-1);
  font-weight: 500;
  font-size: 10px;
}

.categoryName {
  width: 100%;
  text-align: center;
  border-top: 1px solid var(--bl-border);
  font-weight: 500;
  font-size: 0.675rem;
  padding: 3px 0;
  color: var(--bl-header);
}

.partnerBlock>div:first-child {
  margin-top: 20px;
}

.audienceDestinationModalWrap .ant-modal-footer:has(.modalCustomFooterBtnWrap) {
  display: flex;
  align-items: center;
  justify-content: end;
}

.audienceDestinationModalWrap .ant-modal-footer:has(.currentlySelectedPartner) {
  justify-content: space-between;
}

.audienceDestinationModalWrap .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}

.currentlySelectedPartner {
  border: 1px solid var(--bl-border);
  padding: 8px 20px;
  border-radius: 8px;
  font-weight: 500;
}

.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item {
  margin-inline-end: 8px;
  border-radius: 25px;
  padding-inline-end: 20px;
}

.ant-select-selection-overflow-item {
  padding: 2px 0;
}

.addPatientHealthAttribute .ant-btn .anticon {
  font-size: 20px;
}

.audienceAlert {
  padding: 10px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  border-radius: 8px;
}

.audienceAlertInfo {
  border: 1px solid var(--bl-primary);
  background-color: #92B1EF;
}

.audienceSetupPage .audienceAlert {
  margin: 24px;
  color: var(--bl-header);
}

.audienceAlert .leftIcon {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 8px;
}

.audienceAlert p {
  width: calc(100% - 15px);
  font-weight: 600;
}

.audienceAlertInfo .leftIcon {
  background: url(../images/Checkmark-blue.svg) no-repeat center/24px;
}

.audienceAlertWarning .leftIcon {
  background: url(../images/warning-yellow.svg) no-repeat center/24px;
}

.audienceAlertError .leftIcon {
  background: url(../images/error-red.svg) no-repeat center/24px;
}

.audienceAlertError {
  border: 1px solid #CC2525;
  background-color: #F9BFBF;
}

.audienceAlertSuccess {
  border: 1px solid #35AB33;
  background-color: #C2E6C2;
}

.audienceAlertSuccess .leftIcon {
  background: url(../images/Checkmark-green.svg) no-repeat center/24px;
}

.closeCTA {
  height: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.closeCTA img {
  width: 24px;
  height: 24px;
}

.partnerImg img {
  height: 40px;
  width: 82px;
  object-fit: contain;
  margin-bottom: 5px;
}

.reviewAudienceInfo span {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  font-size: 0.875rem;
}

.reviewAudienceInfo span:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -12px;
  width: 4px;
  height: 4px;
  background-color: var(--bl-header);
  border-radius: 50%;
}

.infoElemWrap>div:has(.reviewAudienceInfo) {
  width: 100%;
  box-shadow: 0 4px 8px #0000000d;
}

.audienceAlertWarning {
  background-color: #F6F1C3;
  border: 1px solid #f7e759;
}

.audienceSetupPage .audienceAlert.audienceAlertWarning {
  color: #645a00;
}

.audienceAlertWarning img {
  margin-right: 10px;
  margin-top: 2px;
}

.audienceAlertInfo.audienceAlertWarning {
  margin: 0;
}

.infoElemWrap .chart1 .reviewAudienceInfo {
  margin-top: 0;
  margin-bottom: 10px;
}

.audienceDestinationModal .modalHeader p:nth-child(3) {
  font-size: 1rem;
}

.editPartner {
  color: var(--bl-header);
  font-weight: 600;
  font-size: 0.75rem;
}

.editPartner button {
  background-color: transparent;
  border: none;
  text-decoration: underline;
}

.editPartner+.sizeEstimation .partnerDataSelectWrap {
  margin-top: 0;
}

.sizeEstimation>div:nth-child(2) {
  width: calc(100% - 190px);
  padding-left: 16px;
  margin-top: -20px;
}

.sizeEstimation {
  display: flex;
  margin-top: 10px;
}

.ant-radio-wrapper-checked .partnerBlock {
  background-color: var(--bl-blue-light-2);
  border: 1px solid var(--bl-primary);
}

.ant-radio-wrapper-checked .categoryName {
  border-top: 1px solid var(--bl-primary);
}

.ant-modal.audienceDestinationModalWrap div[tabIndex="-1"] {
  height: 100%;
}

.ant-modal.audienceDestinationModalWrap .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.searchPartnerWrap .eachCommonElementWrapper {
  width: 100%;
}

.audienceActivationTable .audienceAlert {
  margin: 24px 0px;
}

.adminoptionAccordion .ant-collapse-item {
  display: flex;
}

.dropdownSaveButton {
  font-weight: bold;
}

.aiIcon {
  padding-right: 10px;
}

.infoElemWrap .chart1 .aiSummary p {
  color: var(--bl-grey-1);
  margin-top: 0;
}

.ant-table-wrapper:has(th.genericTableID) tbody tr td:first-child {
  opacity: 50%;
}

.ant-select-selection-search:has(input:disabled)~.ant-select-selection-placeholder {
  color: var(--bl-grey-1);
}

.audienceDefinitionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audienceDefinitionHeader button {
  background: transparent url(../images/export.svg) no-repeat center/24px;
  font-size: 0;
  border: none;
  padding: 13px;
  transition: 0.2s all;
}

.audienceDefinitionHeader button:hover {
  background-image: url(../images/export-blue.svg);
}

.uploadFileBaseStyle.showUploadBorder>div.ant-upload-list,
.uploadFileWrapper .npiDisplayContainer {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-left: 20px;
  width: 100%;
  cursor: default;
}

.uploadFileBaseStyle>div.ant-upload-list,
.uploadFileWrapper .npiDisplayContainer {
  height: 32px;
  overflow-y: auto;
}

.npiDisplayElem {
  display: flex;
  align-items: center;
}

.columnSelectorModal .ant-checkbox-group-item {
  flex: 0 0 49%;
}

.columnSelectorModal h3 {
  margin-bottom: 25px;
  color: var(--bl-header);
}


.columnSelectorModal .ant-checkbox-group-item:not(:last-child) {
  margin-bottom: 10px;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-item {
  min-width: 38px;
  height: 38px;
}

.ant-pagination .ant-pagination-item a {
  padding: 3px 6px;
}

.ant-pagination-simple-pager .ant-pagination-slash {
  content: "per";
}

.chartWrapper {
  position: relative;
  border: 1px solid var(--bl-border);
  background-color: var(--bl-white-background);
  border-radius: 8px;
  min-height: 730px;
  padding: 20px;
}

.chartWrapper>div {
  width: 100%;
}

.chartWrapper.barChartWrapper {
  min-height: 500px;
}

.highlight {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateX(-275%) translateY(-50%);
}

.recharts-legend-item {
  display: flex !important;
  align-items: center;
}

.recharts-default-legend {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.audienceTypeWrap {
  display: flex;
  width: 100%;
  min-height: 120px;
  align-items: center;
}

.audienceTypeWrap>span {
  display: inline-block;
  padding: 10px;
  text-align: center;
}

.commonAudienceTableWrap {
  margin-top: 30px;
}

.dashboardContent .filterWrap {
  margin: 0;
}

.anychart-credits {
  display: none;
}

.commonAudienceTableWrap table {
  min-height: 315px;
}

.ant-menu-submenu-popup.ant-menu-submenu,
.ant-dropdown .ant-dropdown-menu {
  box-shadow: rgba(0, 0, 0, 0.75) 10px 9px 33px -22px;
}

.ant-menu-submenu-popup .ant-menu-vertical {
  box-shadow: none;
}

.ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: transparent;
  color: var(--bl-primary);
}

.histogram p {
  color: var(--bl-grey-1);
  font-size: 1rem;
}

.histogram>div {
  width: 100%;
  height: 120px;
}

.pieChartRow {
  border: 1px solid var(--bl-border);
  background-color: #EDEDE8;
  padding: 20px 10px;
  border-radius: 8px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.histogramElem>div {
  margin-left: -12px;
}

.historyBtn {
  margin-right: 8px;
  filter: grayscale(1);
  height: 22px;
  width: 22px;
}

.historyBtn:hover {
  filter: grayscale(0);
}

.historyBtn.disabledElem:hover {
  filter: grayscale(1);
}

.chartWrapper.genericChartWrap {
  min-height: 392px;
}

.genericChartWrap .histogramElem {
  height: 350px;
}

.genericChartWrap .histogramElem>div {
  margin-left: 0;
}

.customIndexChartTable {
  width: calc(100% - 48px);
  border-collapse: collapse;
  background: var(--bl-white-background);
  margin: auto;
  border: 1px solid var(--bl-border);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 0 1px var(--bl-border);
}

.customIndexChartTable tr td:nth-child(3) {
  border-right: 1px solid var(--bl-border);
}

.customIndexChartTable td {
  width: 20%;
  padding: 15px;
}

.customIndexChartTable th {
  padding: 15px;
}

.customIndexChartTable tr:not(:last-child) td,
.customIndexChartTable th {
  border-bottom: 1px solid var(--bl-border);
}

.customIndexChartTable :where(th:nth-child(1), th:nth-child(2), th:nth-child(4)) {
  text-align: left;
}

.dynamicColorBlock {
  min-width: 0;
  display: inline-block;
  height: 20px;
  vertical-align: middle;
}

.customIndexChartTable tbody td:nth-child(3) {
  text-align: right;
  padding-right: 0;
}

.customIndexChartTable tbody td:nth-child(4) {
  padding-left: 0;
}

.visualizationUIHeaderTag {
  background: var(--bl-primary);
  color: var(--bl-white-text);
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid var(--bl-dark-1);
  text-transform: uppercase;
  font-weight: 700;
}

.visualizationTable.customIndexChartTable {
  width: 100%;
  box-shadow: 0 0 0 1px #f0f0ef;
}

.visualizationTable.customIndexChartTable tr:not(:last-child) td,
.customIndexChartTable th {
  border-bottom: 1px solid #f0f0ef;
}

.visualizationTable.customIndexChartTable tr td:nth-child(3) {
  border-right: 1px solid #f0f0ef;
}

.visualizationTableWrap {
  border: 1px solid var(--bl-border);
  border-radius: 8px;
  padding: 20px;
  background-color: var(--bl-white-background);
}

.visualizationTableWrap:not(:first-child) {
  margin-top: 16px;
}

.visualizationTableWrapHeading {
  margin-bottom: 16px;
}

.visualizationUIWrapElement .commonAudienceTableWrap {
  margin-top: 0;
}

.visualizationUIWrapElement .ant-table-content {
  border: 1px solid #f0f0ef;
}

.visualizationUIWrapElement>div {
  margin-bottom: 30px;
}

.visualizationUIWrapElement .commonAudienceTableWrap .sectionHeader h2 {
  font-weight: 400;
  font-size: 0.875rem;
  margin-bottom: 8px;
}

.visualizationUIWrapElement .ant-row {
  margin-bottom: 16px;
}

.visualizationUIHeaderSubTag {
  width: max-content;
  background: var(--bl-blue-light);
  padding: 6px 12px;
  border-radius: 6px;
  margin-bottom: 10px;
  border: 1px solid var(--bl-primary);
}

@media screen and (max-width: 1199px) {
  .responsiveCTAForRightPanel {
    visibility: visible;
  }

  .rightChartWrap {
    display: none;
  }

  .rightChartWrap.collapsedRightPanel {
    display: block;
  }

  .featureSetElementSingleLeft {
    flex: 0 0 94%;
  }

  .rightChartWrap.collapsedRightPanel .chartElemWrap {
    flex-wrap: wrap;
  }

  .newFeatureOptions {
    flex: 0 0 80px;
    width: 80px;
  }

  .createResearchElementWrap>div:not(:last-child) .multiSelectDropdown {
    max-width: 200px;
    flex: 0 0 200px;
  }

  .createResearchElementWrap>div:last-child .multiSelectDropdown {
    max-width: 290px;
  }

  .uploadFileWrapper>div.npiDisplayElemBtnAndElement {
    flex-direction: column;
    align-items: start;
  }

  .npiDisplayElem>span:nth-child(2) {
    text-overflow: initial;
    white-space: initial;
    max-width: none;
  }

  .uploadFileWrapper .npiDisplayContainer {
    margin-left: 0;
    margin-top: 5px;
    height: auto;
  }

  .dashboardContent .ant-col-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

}

@media screen and (max-width: 1024px) {
  .defaultFeatureSetSection .ant-row>div:not(:first-child).ant-col {
    margin-top: 20px;
  }

  .dualSelectorWrapper .ant-col:not(:last-child) {
    padding-right: 0;
    margin-bottom: 5px;
  }

  .adminOptionElem:nth-child(2) {
    flex-direction: column;
    align-items: self-start;
  }

  .adminOptionInnerElem:nth-child(2) {
    margin-left: 0;
    margin-top: 16px;
  }

  .adminOptionInnerElem:nth-child(2) p:first-child {
    flex: 0 0 240px;
  }
}