/* Common */
.singleSelectDropdown,
.multiSelectDropdown {
  width: 100%;
}

.dateRangePickerWrapper {
  display: flex;
  width: 100%;
}

.datePickerWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 7px;
  border-radius: 8px;
}

.warningText {
  background: #ffe500;
  padding: 8px;
  color: black;
  border-radius: 4px;
  margin-bottom: 7px;
}

.datePickerWrapper .ant-picker-input input {
  cursor: pointer;
}

.datePickerWrapper .ant-picker-input input::placeholder {
  text-transform: capitalize;
}

.formWrapper .ant-row > div {
  margin: 12px 0;
}

.formWrapper .ant-row.dualSelectorWrapper > div {
  margin: 0;
}

.featureSetWrap.formWrapper .ant-row > div {
  width: 100%;
}

.eachCommonElementWrapper {
  width: calc(100% - 150px);
  /* margin: 0 15px; */
}

.eachCommonElementWrapper:has(.payerWrap) {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.eachCommonElementWrapper div.audienceActivationTab {
  width: 100%;
}

/* End */

/* Label */
.elementLabel,
.genericAddingRecordModal .ant-modal-body label {
  color: var(--bl-grey-1);
  font-weight: 600;
  font-size: 0.75rem;
  margin: 0 13px 5px 0;
  flex: 0 0 150px;
}

/* .elementLabel.audienceActivationAudienceSize {
  margin: 0 0 15px 2px;
} */

.isMandatory {
  color: #ff0000c9;
}

/* End */

/* Checkbox list */
.checkboxListWrapper {
  width: fit-content;
  position: relative;
  margin: 10px 0;
}

.checkboxListWrapper .checkboxListCTA {
  z-index: 3;
}

.checkboxListWrapper .eachCommonElementWrapper {
  position: absolute;
  top: 6px;
  width: 0;
  margin: 0;
  right: 30px;
}

.checkboxListWrapper .eachCommonElementWrapper .multiSelectDropdown {
  width: 0;
  padding: 0;
  margin: 0;
}

.checkboxListDropdownPopup.ant-select-dropdown
  .ant-select-item.disableEventTrigger {
  pointer-events: none;
}

/* End */

/* Slider */
/* .commonSliderComponent {
  display: flex;
  align-items: center;
  height: 32px;
} */

.commonSliderComponent .sliderShowValue {
  width: 55px;
  height: inherit;
  text-align: center;
  line-height: 24px;
  background-color: #fff;
  padding: 4px 0px;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

/* .commonSliderComponent .sliderBody {
  width: 100%;
  margin: 0 10px;
} */

/* End */

/* upload */
.uploadFileWrapper > div {
  display: flex;
  align-items: center;
}

.uploadFileText {
  margin-top: 7px;
}

.uploadFileBaseStyle {
  display: flex;
}

.uploadFileBaseStyle.showUploadBorder > div.ant-upload-list {
  display: none;
}

.uploadFileWrapper .npiDisplayContainer .npiDisplayEachElemWrap {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  font-size: 15px;
}

.uploadFileWrapper
  .npiDisplayContainer
  .npiDisplayEachElemWrap
  .npiDisplayElemPaperClip,
.uploadFileWrapper
  .npiDisplayContainer
  .npiDisplayEachElemWrap
  .npiDisplayElemBin {
  margin: 0 5px;
}

.uploadFileWrapper
  .npiDisplayContainer
  .npiDisplayEachElemWrap
  .npiDisplayElemBin {
  cursor: pointer;
}

.uploadFileBaseStyle
  > div.ant-upload-list
  .ant-upload-list-item-container
  .ant-upload-list-item {
  margin: 4px;
}

.uploadFileBaseStyle
  > div.ant-upload-list
  .ant-upload-list-item-container
  .ant-upload-list-item
  > span.ant-upload-list-item-name {
  cursor: default;
}

/* Upload end */

/* Input Field */
/* .commonInputFieldBodyWrapper {
   width: calc(100% - 30px); 
   margin: 0 15px; 
} */

.audienceActivation .commonInputFieldBodyWrapper {
  width: 100%;
  margin: 0;
}

/* End */

.loginBtn.loginButtonDisable {
  background-color: #b5b5b5;
  border-color: #b5b5b5;
  color: #fff;
}

.ant-form-item-control-input-content .ant-input,
.ant-form-item-control-input-content span.loginPageField,
.ant-form-item-control-input-content span.loginPageField .ant-input {
  color: #667085;
  background: #ffffff;
}

.ant-form-item-control-input-content span.loginPageField .ant-input:disabled,
.ant-form-item-control-input-content .ant-input.loginPageField:disabled,
.ant-form-item-control-input-content
  span.loginPageField:has(.ant-input:disabled) {
  pointer-events: none;
  color: #c7c7c7;
}

.filterWrap .secondaryBtn:hover {
  border-color: #3170ec;
  color: #3170ec;
}

.addNewFeaturesetbtn .secondaryBtn:hover:disabled {
  box-shadow: none;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: #fafafa;
}

.statusTagSelect .ant-tag {
  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  border-radius: 20px;
  line-height: normal;
  padding: 4px 6px;
  align-items: center;
}

.statusTagSelect .ant-tag img {
  margin-right: 4px;
  height: 12px;
  width: 12px;
}

/* Save button disable */
.primaryBtn:disabled,
.primaryBtn:hover:disabled {
  cursor: not-allowed;
  background: #efefef;
  color: var(--bl-grey-2);
  border: 1px solid transparent;
}

/* end */

/* .primaryBtn.addAudienceBtn {
  margin-top: 18px;
} */

.filterWrap .ant-row {
  max-width: 1140px;
  flex-basis: calc(100% - 150px);
}

.activeAudienceCommonWrap .filterWrap .ant-row {
  max-width: 100%;
  flex-basis: 100%;
}

body.disableScroll {
  overflow: hidden;
}

.messageLoader {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.editIcon {
  background: url(../images/edit-default.svg) no-repeat right center / 18px;
}

.editIcon:hover {
  background: url(../images/edit-hover.svg) no-repeat right center / 18px;
}

.deleteIcon {
  background: url(../images/delete-default.svg) no-repeat right center / 18px;
}

.deleteIcon:hover {
  background: url(../images/delete-hover.svg) no-repeat right center / 18px;
}

.downloadIcon {
  background: url(../images/download-default.svg) no-repeat right center / 18px;
}

.downloadIcon:hover {
  background: url(../images/download-hover.svg) no-repeat right center / 18px;
}

.iconBase {
  width: 18px;
  aspect-ratio: 1;
  cursor: pointer;
}

.LeftPanelProfileMenu {
  width: 100%;
}

.leftPanelMenu li:last-child.ant-menu-item li {
  width: 100%;
  /* padding-inline: 32px; */
  font-weight: 400;
  flex: 0 0 auto;
  display: flex;
}

.elementLabel.audienceListDateRangeFilterWrap {
  margin-left: 2px;
}

.eachCommonElementWrapper.audienceListDateRangeFilterWrap {
  margin-left: 0;
  width: calc(100% - 10px);
}

.dmaSelectionDDOptionWrap.ant-select-dropdown
  .ant-select-item
  .ant-select-item-option-content {
  margin-right: 15px;
}

.dmaSelectionDDOptionWrap.ant-select-dropdown
  .ant-select-item.ant-select-item-option-selected {
  font-weight: normal;
}

.dmaSelectionDDOptionWrap.ant-select-dropdown
  .ant-select-item.ant-select-item-option-selected
  .ant-select-item-option-content {
  margin-right: 0;
}

.targetingElementWrap {
  min-height: 88px;
}

.targetingElementWrap .noTargetingFeatureSet {
  text-align: center;
  font-weight: 600;
  color: #b5b5b5;
  font-size: 18px;
  padding: 36px;
}

.sliderTooltipWrap {
  line-height: normal;
  pointer-events: none;
  border-radius: 6px;
  box-shadow: none;
  z-index: 100;
}

.sliderTooltipWrap .ant-tooltip-arrow {
  width: 0;
}

.sliderTooltipWrap .ant-tooltip-content .ant-tooltip-inner {
  color: var(--bl-grey-1);
  background-color: transparent;
  border: 1px solid transparent;
  pointer-events: none;
  outline: none;
  padding: 2px;
  box-shadow: none;
  font-weight: 400;
  font-size: 10px;
}

.elementLabel.ageSliderLabel {
  margin-bottom: 0;
}

div.debounceDDOptionElemWrap {
  display: flex;
}

div.debounceDDOptionElemWrap div.featureItemID {
  font-weight: 600;
  margin-right: 6px;
}

div.debounceDDOptionElemWrap div:last-child {
  white-space: normal;
  line-break: auto;
}

/* .debounceDropdownWrapper
  .ant-select-multiple.ant-select-show-arrow
  .ant-select-selector {
  min-height: 36px;
  max-height: 154px;
  overflow-x: auto;
  padding-inline-start: 2px;
  padding-inline-end: 22px;
} */

/* tag not responsive */
.ant-select-selection-overflow-item:has(span.debounceDDTagWrapper) {
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid transparent;
  border-radius: 20px;
  margin: 2px;
}

.debounceDDTagWrapper {
  line-height: 22px;
  font-size: 14px;
  background: transparent;
  border: 1px solid transparent;
  white-space: normal;
  word-break: break-word;
  max-width: inherit;
  padding-inline-end: 26px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.validationErrorIcon {
  font-size: 18px;
  color: #ff0000c9;
}

.audienceSetupPage .leftPanelWrap ~ .errorScreenWrap {
  margin: 0 0 0 282px;
  transition: 0.3s ease all;
}

.audienceSetupPage .leftPanelWrap.leftPanelCollapsed ~ .errorScreenWrap {
  margin: 0 0 0 100px;
  transition: 0.3s ease all;
}

.addAccountPage .leftPanelWrap ~ .errorScreenWrap {
  margin: 0 0 0 282px;
  transition: 0.3s ease all;
}

.addAccountPage .leftPanelWrap.leftPanelCollapsed ~ .errorScreenWrap {
  margin: 0 0 0 100px;
  transition: 0.3s ease all;
}
.clickableTextField {
  color: #3170ec;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: inherit;
}

.clickableTextField:hover {
  cursor: pointer;
}

.checkboxListWrap {
  /* margin: 0 15px; */
  padding-top: 4px;
}

.checkboxListWrap label.ant-checkbox-wrapper.ant-checkbox-group-item {
  display: flex;
  margin: 0;
}

.saveBtn.newFeatureSetBtn > div.dropdownSaveButton {
  width: auto;
  margin-right: 20px;
}

/* .uploadFileWrapper .npiDisplayContainer::-webkit-scrollbar,
.debounceDropdownWrapper .ant-select-selector::-webkit-scrollbar {
  width: 8px;
  cursor: default;
}

.uploadFileWrapper .npiDisplayContainer::-webkit-scrollbar-thumb,
.debounceDropdownWrapper .ant-select-selector::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #dadada;
}

.uploadFileWrapper .npiDisplayContainer::-webkit-scrollbar-thumb:hover,
.debounceDropdownWrapper .ant-select-selector::-webkit-scrollbar-thumb:hover {
  background: #9b9b9b;
  cursor: default;
} */

.audienceActivation .audienceActivationPageText1 {
  font-size: 12px;
  margin-bottom: 30px;
}

.audienceActivation .audienceActivationPageText2 {
  margin-bottom: 15px;
}

.anticon-delete {
  color: var(--bl-grey-1);
}

.anticon-delete:hover {
  color: #ff0000;
}

.hideNoDataLabel .ant-empty {
  opacity: 0;
}

.disabledElement {
  color: var(--bl-grey-1);
}

.textHyperLink .disabledElement {
  color: var(--bl-grey-1);
}


.expirationEmailBody {
  background-color: #fff;
  text-align: center;
  padding: 10px 0;
  font-weight: 600;
  font-size: 20px;
  border-radius: 6px;
}

.dashboardCTAWrap .labelAndCTA .noBorderBtn {
  margin-right: 12px;
  text-decoration: none;
  font-size: 14px;
}

.dashboardCTAWrap .labelAndCTA button:last-child.noBorderBtn {
  margin-right: 0;
  padding: 0;
}

.archivedText{
  color: var(--bl-icon-red);
  font-weight: 700;
  display: inline-block;
  margin-right: 5px;
}

.archivedText ~ a.textHyperLink:hover{
  color: var(--bl-grey-1);
}
.visualizationTableWrap{
  height: 100%;
}

.visualizationTableWrap td {
  padding: 7px;
}

.visualizationTable.customIndexChartTable tr.noBorder td:nth-child(3) {
  border-right: 0px solid #f0f0ef;
}
